// AUTOGENERATED - MANUALLY CHANGES WILL BE REVERTED BY THE GENERATOR
// IPoolAddressesProvider https://blockscout.lisk.com/address/0xDca1A174BccF03366aB72bb156718A5719D44023
export const POOL_ADDRESSES_PROVIDER = '0xDca1A174BccF03366aB72bb156718A5719D44023';

// IPool https://blockscout.lisk.com/address/0x617a09f69493560f23B8Da05ADf98CE3B52d7A99
export const POOL = '0x617a09f69493560f23B8Da05ADf98CE3B52d7A99';

// IPoolConfigurator https://blockscout.lisk.com/address/0x902fa59532B46Ec1834F911bB7Bc51A6172332dE
export const POOL_CONFIGURATOR = '0x902fa59532B46Ec1834F911bB7Bc51A6172332dE';

// IRASAOracle https://blockscout.lisk.com/address/0x286835fb2Ec6a893B0D1B0ee8C8aF28773F21F19
export const ORACLE = '0x286835fb2Ec6a893B0D1B0ee8C8aF28773F21F19';

// https://blockscout.lisk.com/address/0x0000000000000000000000000000000000000000
export const PRICE_ORACLE_SENTINEL = '0x0000000000000000000000000000000000000000';

// IPoolDataProvider https://blockscout.lisk.com/address/0x904A08D1401B4640b886f7bAaf10F248A1dDA33D
export const PROTOCOL_DATA_PROVIDER = '0x904A08D1401B4640b886f7bAaf10F248A1dDA33D';

// IACLManager https://blockscout.lisk.com/address/0xBB3C4345568184403cD8baE3550D85d4C5cA0DAa
export const ACL_MANAGER = '0xBB3C4345568184403cD8baE3550D85d4C5cA0DAa';

// https://blockscout.lisk.com/address/0x2Fa76F2a6c7EcB9155a97445C371bfAEC795782E
export const ACL_ADMIN = '0x2Fa76F2a6c7EcB9155a97445C371bfAEC795782E';

// ICollector https://blockscout.lisk.com/address/0x4AfEdE13823912b3103D2B31e04Ea2f9ccF1F09B
export const COLLECTOR = '0x4AfEdE13823912b3103D2B31e04Ea2f9ccF1F09B';

// https://blockscout.lisk.com/address/0x0e6F4a44b17Df36a62Bac756b1922d3657F401CC
export const DEFAULT_INCENTIVES_CONTROLLER = '0x0e6F4a44b17Df36a62Bac756b1922d3657F401CC';

// https://blockscout.lisk.com/address/0x27FB34A8899E417e2a63A4c815E50A6231fA4bb4
export const DEFAULT_RS_TOKEN_IMPL_REV_1 = '0x27FB34A8899E417e2a63A4c815E50A6231fA4bb4';

// https://blockscout.lisk.com/address/0xaF6E61f0d5583a6b3Fb7D70C122516132c816C48
export const DEFAULT_VARIABLE_DEBT_TOKEN_IMPL_REV_1 = '0xaF6E61f0d5583a6b3Fb7D70C122516132c816C48';

// https://blockscout.lisk.com/address/0x0e047d99b2C65011CA0585462a131d9ae52fc199
export const DEFAULT_STABLE_DEBT_TOKEN_IMPL_REV_1 = '0x0e047d99b2C65011CA0585462a131d9ae52fc199';

// https://blockscout.lisk.com/address/0x534C201fd057992c14adbb17fc77ed4B787CE562
export const EMISSION_MANAGER = '0x534C201fd057992c14adbb17fc77ed4B787CE562';

// https://blockscout.lisk.com/address/0x9Ad4e877597DB4158453706cE667513ACbd0FA3D
export const POOL_ADDRESSES_PROVIDER_REGISTRY = '0x9Ad4e877597DB4158453706cE667513ACbd0FA3D';

// https://blockscout.lisk.com/address/0x1EdEaA95dEb0eA5925E2Ff134cAB2ec1075bf0a5
export const UI_INCENTIVE_DATA_PROVIDER = '0x1EdEaA95dEb0eA5925E2Ff134cAB2ec1075bf0a5';

// https://blockscout.lisk.com/address/0xC077a91e7F2D0b4EfCC2772aE4c6A28967e68d39
export const UI_POOL_DATA_PROVIDER = '0xC077a91e7F2D0b4EfCC2772aE4c6A28967e68d39';

// https://blockscout.lisk.com/address/0xb51b66413284c4182596005f18Bf5620a3B1D836
export const WALLET_BALANCE_PROVIDER = '0xb51b66413284c4182596005f18Bf5620a3B1D836';

// https://blockscout.lisk.com/address/0x49261f66c91cC02BEF40C4ad4Be843407AF14414
export const WETH_GATEWAY = '0x49261f66c91cC02BEF40C4ad4Be843407AF14414';

export const CHAIN_ID = 1135;
export const ASSETS = {
  WETH: {
    decimals: 18,
    UNDERLYING: '0x4200000000000000000000000000000000000006',
    RS_TOKEN: '0x817a34792482afB67703a74d2dBD15eA7E8b3E55',
    S_TOKEN: '0x5e7220738e09be7C255921d00E066e6c2F1D4537',
    V_TOKEN: '0x50533E5c61528C0A24454525D145D9C71F3F98B2',
    INTEREST_RATE_STRATEGY: '0x1a9E7cA1dBb8db98fa2BC06faA5b865De33F652f',
    ORACLE: '0xE4Fbc2b3fE1553FF9b8159C7522f328eCF828F32',
  },
  LSK: {
    decimals: 18,
    UNDERLYING: '0xac485391EB2d7D88253a7F1eF18C37f4242D1A24',
    RS_TOKEN: '0xeB3cb4bB6B5Dc2498E3733B485a6DD70a080258b',
    S_TOKEN: '0x774774b402052819f5de4C2ECcF89Cc404871d4f',
    V_TOKEN: '0x2B55f6fEC3e90A0E8EB5eA46E69074BC71B6e14C',
    INTEREST_RATE_STRATEGY: '0x1a9E7cA1dBb8db98fa2BC06faA5b865De33F652f',
    ORACLE: '0x7542c75242494947Db0B5ccd48051284808a01d6',
  },
  USDT: {
    decimals: 6,
    UNDERLYING: '0x05D032ac25d322df992303dCa074EE7392C117b9',
    RS_TOKEN: '0xda1624daE22C0f3A55b3B0e55EE719a01F99eE58',
    S_TOKEN: '0xf9C43b551ed0792b5b62C5B9fB339F93B351906A',
    V_TOKEN: '0xc519587aa4776FFa88F31Eade21F1DAB21Be8Da9',
    INTEREST_RATE_STRATEGY: '0x1fAfF2Cdc6E3ede94F8abEC6e4899d9836e01719',
    ORACLE: '0x544609AE882AEdbf65b529b18AFbf2866E4A1acF',
  },
} as const;
export const E_MODES = {
  NONE: 0,
  STABLECOINS: 1,
} as const;
